<template>
  <div>
    <h1 class="ui-title-page">Become A RealHub Field Assistant</h1>
    <div class="triagl triagl-btm"></div>
    <section
      class="section-area section_slider-reviews bg-gray section-testimoials"
      v-if="!loading"
    >
      <template v-if="!submitted">
        <h2 class="ui-title-block">Join The Team</h2>
        <div class="ui-subtitle-block">extra income is always welcome.</div>
        <div class="border-color border-color_small"></div>
      </template>
      <div class="container">
        <div class="row" v-if="!submitted">
          <div class="col-md-6" v-if="!edit_content">
            <div class="card text-left has-edit server-content">
              <edit-button @click="edit_content = true" />
              <div v-html="content"></div>
            </div>
          </div>
          <div class="col-md-12" v-if="edit_content">
            <div class="card text-left">
              <h3>Edit Job Description</h3>
              <hr />
              <form @submit.prevent="updateContent">
                <div class="form-group">
                  <editor api-key="rn7fdm7awd3udl7l5i2trwivbv6k6udjh9mhinmm653d99uh"
                    v-model="content"
                    :init="{
                      height: 700,
                      menubar: true,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount',
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | removeformat | image',
                    }"
                  />
                </div>
                <div class="form-group">
                  <button class="btn btn-primary">
                    <i class="lni lni-save"></i>Save
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-6" v-if="!edit_content">
            <div class="card text-left">
              <h3 style="margin-top: 0">Apply Now</h3>
              <hr />
              <form @submit.prevent="submitApplication">
                <div class="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    placeholder="Full Name"
                    class="form-control custom"
                    required
                    v-model="user.name"
                  />
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        placeholder="Email"
                        class="form-control custom"
                        required
                        v-model="user.email"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Phone Number</label>
                      <input
                        type="text"
                        placeholder="Phone Number"
                        class="form-control custom"
                        required
                        v-model="user.phone_number"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Date Of Birth</label>
                      <date-picker v-model="user.dob" input-class="form-control" placeholder="Date Of Birth"  />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Gender</label>
                      <select v-model="user.gender" class="form-control">
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="form-group" id="select-region">
                  <label>Where do you live?</label>
                  <select-region v-model="user.region_id" />
                  <span class="text-danger" v-if="region_has_error"
                    >* Select or add your region.</span
                  >
                </div>
                <div class="form-group">
                  <label>Introduce youself. </label>
                  <textarea
                    rows="10"
                    class="form-control"
                    placeholder="Tell us about yourself."
                    required
                    v-model="user.intro"
                  ></textarea>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Upload your CV</label>
                      <input
                        type="file"
                        placeholder="Select File"
                        ref="application_cv"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Upload any other relevant documents.</label>
                      <input
                        type="file"
                        placeholder="Select File"
                        ref="application_docs"
                        class="form-control"
                        multiple
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <button class="btn btn-primary">
                    <i class="lni lni-save"></i> Apply Now
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="card text-center" v-if="submitted">
          <h2>Your application has been received.</h2>
          <p>We will review your details and get in touch shortly.</p>
          <p><a href="/careers" class="btn btn-primary">Okay. Go Back</a></p>
        </div>
      </div>
    </section>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      loading: true,
      edit_content: false,
      content: "",
      user: {},
      region_has_error: false,
      submitted: false,
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios.get("/api/v1/careers/field-assistant").then((response) => {
        this.content = response.data.content;
        this.loading = false;
      });
    },

    updateContent() {
      this.loading = true
      let data = {
        content: this.content,
        _method: "PUT",
      };

      this.$axios
        .put("/api/v1/admin/careers/field-assistant/content", data)
        .then(() => {
          this.edit_content = false;
          this.loading = false
          window.scroll(0, 0);
        });
    },

    submitApplication() {
      if (this.user.region_id == null) {
        this.region_has_error = true;
        document.getElementById("select-region").scrollIntoView();
      } else {
        this.submitFormData()
      }
    },

    submitFormData() {
      this.submitted = false;
      this.loading = true;
      let data = new FormData;
      data.append('name', this.user.name)
      data.append('email', this.user.email)
      data.append('phone_number', this.user.phone_number)
      data.append('region_id', this.user.region_id)
      data.append('dob', this.user.dob)
      data.append('gender', this.user.gender)
      data.append('intro', this.user.intro)
      data.append('cv', this.$refs.application_cv.files[0])

      Object.values(this.$refs.application_docs.files).forEach(file => {
        data.append(`doc[]`, file)
      })

      this.$axios.post("/api/v1/careers/field-assistant", data).then(() => {
        window.scroll(0, 0)
        this.submitted = true;
        this.loading = false;
      });
    }
  },
};
</script>
